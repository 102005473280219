// const baiduTranslateURL = "http://127.0.0.1:8000/proxy/translate";
const baiduTranslateURL = "https://api.coderxst.top/proxy/translate";

/**
 * 语言翻译
 * @param { string } query 需要翻译的字符
 * @param { string } from default 'auto'
 * @param { string } to default 'en'
 * @returns
 */
export const translate = async (query, from = "auto", to = "en") => {
  const url = baiduTranslateURL + `?query=${query}&from=${from}&to=${to}`;
  const resp = await fetch(url);
  const result = await resp.json();
  return result;
};

export default translate;
